<template>
  <v-card
    id="console-notification-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <console-notification-tab-general
          :data-params="generalData"
          :tab="tab"
          :option="option"
          :roles="roles"
          :groups="groups"
          @changeTab="changeTab"
          @save="onSave"
        ></console-notification-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import { createConsoleNotification, editConsoleNotification, getGroups, getRoles } from '@api'

// demos
import ConsoleNotificationTabGeneral from './ConsoleNotificationTabGeneral.vue'

export default {
  components: {
    ConsoleNotificationTabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { userData } = useCryptoJs()

    const tab = ref(0)
    const option = ref(0)
    const id = ref(null)
    const generalData = ref({
      user_id: userData.value.username,
      notification_text: null,
      groups_ids: [],
      roles_ids: [],
      status: null,
      notification_type: null,
      border: null,
      notification_color: null,
      icon: null,
      size: null,
      dismissible: false,
      colored_text: false,
      outlined: false,
      html_code: null,
    })
    const postData = ref({
      user_id: userData.value.username,
      notification_text: null,
      groups_ids: [],
      roles_ids: [],
      status: null,
      notification_type: null,
      border: null,
      notification_color: null,
      icon: null,
      size: null,
      dismissible: false,
      colored_text: false,
      outlined: false,
      html_code: null,
    })
    const putData = ref({
      id: null,
      user_id: userData.value.username,
      notification_text: null,
      groups_ids: [],
      roles_ids: [],
      status: null,
      notification_type: null,
      border: null,
      notification_color: null,
      icon: null,
      size: null,
      dismissible: false,
      colored_text: false,
      outlined: false,
      html_code: null,
    })
    const roles = ref([])
    const groups = ref([])

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    onMounted(async () => {
      option.value = route.value.params.option
      if (option.value !== 1) {
        id.value = route.value.params.id
        generalData.value = route.value.params.dataParams
        generalData.value.allGroups = generalData.value.groups_ids?.length === 0
        generalData.value.allRoles = generalData.value.roles_ids?.length === 0

        if (!generalData.value) router.go(-1)
        tabs.value.forEach(e => {
          e.disabled = false
        })
        tabs.value.splice(1, 1)
      }

      if (option.value === 1) {
        generalData.value.allGroups = false
        generalData.value.allRoles = false
      }

      const responseGroups = await getGroups()
      groups.value = responseGroups.data

      const responseRoles = await getRoles()
      roles.value = responseRoles.data
    })

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const onSave = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          user_id: userData.value.username,
          notification_text: data.notification_text,
          size: data.size,
          dismissible: data.dismissible,
          notification_color: data.notification_color,
          colored_text: data.colored_text,
          outlined: data.outlined,
          border: data.border,
          icon: data.icon,
          notification_type: data.notification_type,
          html_code: data.html_code,
          groups_ids: JSON.stringify(data.groups_ids),
          roles_ids: JSON.stringify(data.roles_ids),
          status: data.status,
        })

        resp = await createConsoleNotification(postData.value)
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          user_id: userData.value.username,
          notification_text: data.notification_text,
          size: data.size,
          dismissible: data.dismissible,
          notification_color: data.notification_color,
          colored_text: data.colored_text,
          outlined: data.outlined,
          border: data.border,
          icon: data.icon,
          notification_type: data.notification_type,
          html_code: data.html_code,
          groups_ids: JSON.stringify(data.groups_ids),
          roles_ids: JSON.stringify(data.roles_ids),
          status: data.status,
        })
        resp = await editConsoleNotification(putData.value, data.id)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      generalData.value = {
        user_id: userData.value.username,
        notification_text: null,
        groups_ids: [],
        roles_ids: [],
        status: null,
        notification_type: null,
        border: null,
        notification_color: null,
        icon: null,
        size: null,
        dismissible: false,
        colored_text: false,
        outlined: false,
        html_code: null,
      }
    }

    return {
      tab,
      tabs,
      option,
      id,
      generalData,
      postData,
      putData,
      roles,
      groups,
      userData,

      changeTab,
      onSave,
      cancelAction,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
