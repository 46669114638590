<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="8"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.notification_text"
              :label="t('notifications.title')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="dataComputed.allGroups"
              class="ma-auto"
              :label="t('notifications.all_groups')"
              color="secondary"
              dense
              hide-details
              @change="onCkeckAllGroups"
            ></v-checkbox>
            <v-select
              v-model="dataComputed.groups_ids"
              :label="t('groups.groups')"
              :items="groupsOptionsComputed"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              multiple
              persistent-hint
              dense
              outlined
              clearable
              class="mb-0"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :disabled="allGroups"
              :rules="allGroups ? [] : [required]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="dataComputed.allRoles"
              class="ma-auto"
              :label="t('notifications.all_roles')"
              color="secondary"
              dense
              hide-details
              @change="onCkeckAllRoles"
            ></v-checkbox>
            <v-select
              v-model="dataComputed.roles_ids"
              :label="t('roles.roles')"
              :items="rolesOptionsComputed"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              multiple
              persistent-hint
              dense
              outlined
              clearable
              class="mb-0"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :disabled="allRoles"
              :rules="allRoles ? [] : [required]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex align-end"
          >
            <v-select
              v-model="dataComputed.notification_type"
              dense
              outlined
              clearable
              :label="t('notifications.type')"
              :items="typeOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.border"
              dense
              outlined
              clearable
              :label="t('notifications.border')"
              :items="borderOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.notification_color"
              dense
              outlined
              clearable
              :label="t('notifications.color')"
              :items="colorOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.icon"
              dense
              outlined
              clearable
              :label="t('notifications.icon')"
              :items="iconOptions"
              item-text="text"
              item-value="value"
              :prepend-icon="computedResolveIcon"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-checkbox
              v-model="dataComputed.dismissible"
              class="ma-auto"
              :label="t('notifications.dismissible')"
              color="secondary"
            ></v-checkbox>

            <v-checkbox
              v-model="dataComputed.outlined"
              class="ma-auto"
              :label="t('notifications.outlined')"
              color="secondary"
            ></v-checkbox>

            <v-checkbox
              v-model="dataComputed.colored_text"
              class="ma-auto"
              :label="t('notifications.colored_text')"
              color="secondary"
            ></v-checkbox>
          </v-col>

          <!-- <v-col
            cols="12"
            md="10"
          >
            <v-textarea
              v-model="dataComputed.html_code"
              :label="t('notifications.text_html')"
              outlined
              rows="5"
            ></v-textarea>
          </v-col> -->

          <v-col
            cols="12"
            md="10"
          >
            <code-editor
              v-model="textHTML"
              :languages="[['html', 'HTML']]"
              :wrap_code="false"
              :copy_code="false"
              width="100%"
              min_height="130px"
              border_radius="5px"
            ></code-editor>
          </v-col>

          <v-col
            cols="12"
          >
            <v-alert
              class="ma-0"
              :border="dataComputed.border"
              :type="dataComputed.notification_type"
              :outlined="dataComputed.outlined"
              :text="dataComputed.colored_text"
              :color="dataComputed.notification_color"
              :dismissible="dataComputed.dismissible"
              :icon="computedResolveIcon"
            >
              <!-- {{ dataComputed.notification_text }} -->
              <div
                v-if="computedTextHTML"
                v-html="computedTextHTML"
              >
              </div>
              <template
                v-if="dataComputed.dismissible"
                v-slot:close="{ toggle }"
              >
                <v-icon
                  @click="() => toggle"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </template>
            </v-alert>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */
import {
  mdiMenuDown,
  mdiClose,
} from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import CodeEditor from 'simple-code-editor'
import useConsoleNotificationsList from './useConsoleNotificationsList'

export default {
  components: {
    OptionsButtomTab,
    CodeEditor,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    roles: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const { t } = useUtils()
    const { resolveIcon } = useConsoleNotificationsList()
    const textHTML = ref('')
    const computedTextHTML = computed(() => textHTML.value)
    const allRoles = ref(true)
    const allGroups = ref(true)
    const dataComputed = computed(() => {
      allGroups.value = props.dataParams.allGroups
      allRoles.value = props.dataParams.allRoles
      textHTML.value = textHTML.value !== '' ? textHTML.value : props.dataParams.html_code

      return props.dataParams
    })
    const rolesOptionsComputed = computed(() => props.roles)
    const groupsOptionsComputed = computed(() => props.groups)
    const computedResolveIcon = computed(() => resolveIcon(dataComputed.value.icon))
    const initialData = {}

    const iconOptions = ref([
      { text: 'mdi-soccer', value: 'mdi-soccer' },
      { text: 'mdi-soccer-field', value: 'mdi-soccer-field' },
      { text: 'mdi-wifi', value: 'mdi-wifi' },
      { text: 'mdi-wifi-off', value: 'mdi-wifi-off' },
      { text: 'mdi-camera', value: 'mdi-camera' },
      { text: 'mdi-camera-off', value: 'mdi-camera-off' },
      { text: 'mdi-camera-switch', value: 'mdi-camera-switch' },
      { text: 'mdi-video', value: 'mdi-video' },
      { text: 'mdi-video-off', value: 'mdi-video-off' },
      { text: 'mdi-video-switch', value: 'mdi-video-switch' },
      { text: 'mdi-temperature-celsius', value: 'mdi-temperature-celsius' },
      { text: 'mdi-battery-alert', value: 'mdi-battery-alert' },
      { text: 'mdi-trophy', value: 'mdi-trophy' },
      { text: 'mdi-van-utility', value: 'mdi-van-utility' },
    ])

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const borderOptions = computed(() => [
      { text: t('border_types.left'), value: 'left' },
      { text: t('border_types.right'), value: 'right' },
      { text: t('border_types.top'), value: 'top' },
      { text: t('border_types.bottom'), value: 'bottom' },
    ])

    const colorOptions = computed(() => [
      { text: t('colors.red'), value: 'red' },
      { text: t('colors.orange'), value: 'orange' },
      { text: t('colors.yellow'), value: 'yellow' },
      { text: t('colors.green'), value: 'green' },
      { text: t('colors.blue'), value: 'blue' },
      { text: t('colors.purple'), value: 'purple' },
    ])

    const typeOptions = computed(() => [
      { text: t('alert_types.success'), value: 'success' },
      { text: t('alert_types.error'), value: 'error' },
      { text: t('alert_types.warning'), value: 'warning' },
      { text: t('alert_types.info'), value: 'info' },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        if (computedTextHTML.value) dataComputed.value.html_code = computedTextHTML.value
        emit('save', dataComputed.value)
      }
    }

    const dataAcount = ref({})

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        user_id: null,
        notification_text: null,
        groups_ids: [],
        roles_ids: [],
        status: null,
        notification_type: null,
        border: null,
        notification_color: null,
        icon: null,
        size: null,
        dismissible: false,
        colored_text: false,
        outlined: false,
        html_code: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const onCkeckAllRoles = e => {
      if (e) {
        allRoles.value = true
        dataComputed.value.roles_ids = []
      } else {
        allRoles.value = false
      }
    }

    const onCkeckAllGroups = e => {
      if (e) {
        allGroups.value = true
        dataComputed.value.groups_ids = []
      } else {
        allGroups.value = false
      }
    }

    onMounted(() => {
      setTimeout(() => {
        textHTML.value = dataComputed.value.html_code || ''
      }, 100)
    })

    return {
      form,
      statusOptions,
      borderOptions,
      colorOptions,
      typeOptions,
      iconOptions,
      dataAcount,
      dataComputed,
      initialData,
      textHTML,
      computedTextHTML,
      rolesOptionsComputed,
      groupsOptionsComputed,
      computedResolveIcon,
      allRoles,
      allGroups,

      changeTab,
      onSaveAction,
      onCancelButton,
      onCkeckAllRoles,
      onCkeckAllGroups,

      // validations
      required,

      // i18n
      t,

      icons: {
        mdiMenuDown,
        mdiClose,
      },
    }
  },
}
</script>
